.NavigationItems{
    top: 0;  
    margin: 0;
    padding: 0;
    margin-top: 50px;
    list-style: none;
    flex-flow: column;
    display: flex;
    align-items: center;
    border: none;
}
@media(max-width: 1100px){
    .NavigationItems a img{
        display: none;
    }

}





@media (min-width: 1100px) {
    .NavigationItems{
        margin-top: 0;
        height: 50px;
        width: 100vw;
        flex-flow: row;
        left: 0;
        top: 0;  
        background-color: var(--color-1);
        z-index: 1000;
    }
    .NavigationItems a img{
        padding: 0 5px;
    }
    .NavigationItems .last_element{
        margin-right: 50px;
    }
}