.dropdown{
    position: absolute;
    right: 20px;
    top: 50px;
    opacity: 1
}


.dropdown .dropdownElement{
    width: 200px;
    height: auto;
    display: grid;
    border: 1px solid rgb(212, 212, 212);
    position: absolute;
    right: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 5px 5px 5px -5px rgb(58, 58, 58);
    border-radius: 20px;
}
.dropdown .dropdownElement button{
    outline: none;
    border: none;
    width: auto;
    height: auto;
    background-color: none;
}
