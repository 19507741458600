@media(min-width: 1100px){
.Button{
    background-color: var(--color-2);
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px;
    margin: 10px;
    font-weight: bold;
    height: 45;
    border-radius: 22.5px;
   
}
.GoogleButton{
    background-color: white;
    border: none;
    color: rgb(71, 71, 71);
    outline: none;
    cursor: pointer;
    border-radius: 2.5px;
    display: flex;
    padding: 10px 16px 8px 16px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
    position: relative;

}

.GoogleButton div{
    padding-right: 16px;
}
.GoogleButton:hover{
    background-color: #4285F4;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    border-radius: 2.5px;
    display: flex;
    padding: 10px 16px 10px 16px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;

 
}
.NextButton{
    background-color: var(--color-2);
    color: rgb(255, 255, 255);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 10px;
    margin: 10px 3px 10px auto;
    font: inherit;
    font-weight: 500;
    height: 45;
    border-radius: 6px;
    width: 100px;
   
}
.NextButton:hover{
    transform: scale(1.1);
}
.PrevButton{
    background-color: var(--color-2);
    color: rgb(255, 255, 255);
    border: none;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 10px;
    margin: 10px auto 10px 0px;
    font-weight: 500;
    height: 45;
    border-radius: 6px;
    width: 100px;
}
.PrevButton:hover{
    transform: scale(1.1);
}
.OpenButton{
    background-color: green;
    /* background-color: rgb(48, 48, 48); */
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px;
    margin: 10px;
    font-weight: bold;
    height: 45;
    border-radius: 22.5px;
}
.CloseButton{
    background-color: red;
    /* background-color: rgb(48, 48, 48); */
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px;
    margin: 10px;
    font-weight: bold;
    height: 45;
    border-radius: 22.5px;
}
.CancelButton{
    background-color: #ccc;
    /* background-color: rgb(48, 48, 48); */
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px;
    margin: 10px;
    font-weight: bold;
    height: 45;
    border-radius: 22.5px;
    width: 100px;
}

.CancelButton:hover{
    opacity: 0.5;
}
.ContinueButton{
    background-color: rgb(53, 53, 53);
    /* background-color: rgb(48, 48, 48); */
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px;
    margin: 10px;
    font-weight: bold;
    height: 45;
    border-radius: 22.5px;
    width: 100px;
}
.ContinueButton:hover{
    opacity: 0.5;
}
.Button:hover{
    background-color: var(--color-4);
    /* background-color: rgba(48, 48, 48, 0.514); */
}
.NavigationButton{
    text-decoration: none;
    background-color: rgb(48, 48, 48);
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    border-radius: 1px;
    height: 45;

}
.EditButton{
    width: auto;
    height: auto;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: red;
    color: white;
    font: inherit;
    font-size: 12px;

}
.EditButton:hover{
    cursor: pointer;
}
.settingsButton{
    font-weight: 500;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    color: transparent;
    text-shadow: 0 0 0 black;
}
.settingsButton:hover{
   transform: scale(1.1);
}

.dropdownButton{
    font-weight: 100;
    padding: 10px 10px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    color: transparent;
    text-shadow: 0 0 0 black;
}
/* .dropdownButton:hover{
    background-color: #a8a8a8;
} */

.settingsButton2{
    font-weight: 100;
    padding: 8px 10px;
    color: white;
    background-color:  var(--color-2);
    border-radius: 5px;
    text-decoration: none;
    max-width: 100px;
    max-height: 25px;
    margin-right: 50px;
}
.settingsButton3{
    font-weight: 500;
    padding: 8px 10px;
    color: white;
    background-color:  var(--color-2);
    border-radius: 5px;
    text-decoration: none;
    max-width: 100px;
    max-height: 25px;
    margin-right: 50px;
}

.ToolbarButton{
    border: none;
    background-color:#18181a;
    height: 100%;
    width: auto;
    padding: 16px 10px;
    text-align: center;
    font-size: 20px;
    outline: none;
}
.Playbutton_background{
    outline: none;
    width: 200px;
    height: 200px;
/*     background-color: rgba(255, 255, 255, 0.733); */
    background-color: var(--color-1);
    border: none;
    border-radius: 50%;
    color: transparent;
    text-shadow: 0 0 0 black;
    
}
.Playbutton_background:hover{
    opacity: 0.8;
}
.Playbutton{
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-width: 50px 0px 50px 100px;
    border-color: transparent transparent transparent #202020;
    border-color: transparent transparent transparent var(--color-5);
   /*  background-color: rgba(204, 204, 204, 0); */
    background-color: rgba(204, 204, 204, 0);
    outline: none;
    margin: 50px 0 0 40px;
    color: transparent;
    text-shadow: 0 0 0 black;

}
.Playbutton:hover{
    cursor: pointer;
}
.Pausebutton{
    width: 80px;
    height: 80px;
    background-color: var(--color-5);
    border: none;
    outline: none;
    margin: auto;
    margin: 60px 0 0 0px;
    color: transparent;
    text-shadow: 0 0 0 black;
}
.Pausebutton:hover{
 cursor: pointer;
}

.ToolbarButton:hover,
.ToolbarButton:active{
    border: none;
    background-color:#18181a;
    box-shadow: inset 0 0 20px #000000;
}
.ToolbarButton:nth-of-type(3){
    margin-right: 50px;
}

.NavigationButton:hover{
    background-color: var(--color-title);
}

.Button:active{
   
    opacity: 0.5;
}

.TriangleRight {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
    margin: auto;
    margin-top: 20px;
}
.TriangleDown {
    width: 0;
    height: 0;
    border-top: 5px solid black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    margin: auto;
    margin-top: 20px;
}
}

@media(max-width: 1100px ){
    .ToolbarButton{
        display: block;
        border: none;
        width: 100%;
        background-color: rgb(248, 248, 248);
        padding: 16px 20px;
        margin-top: 1px;
        font-size: 20px;
        outline: none;
        text-align: start;
        border-left: solid 6px #000000;
    }

    .GoogleButton{
        background-color: white;
        border: none;
        color: rgb(71, 71, 71);
        outline: none;
        cursor: pointer;
        border-radius: 2.5px;
        display: flex;
        padding: 10px 16px 8px 16px;
        font-size: 14px;
        font-weight: bold;
        /* Use the Roboto font that is loaded in the <head> */
        font-family: 'Roboto', sans-serif;
        position: relative;
    
    }
    
    .GoogleButton div{
        padding-right: 16px;
    }
    .GoogleButton:hover{
        background-color: #4285F4;
        border: none;
        color: white;
        outline: none;
        cursor: pointer;
        border-radius: 2.5px;
        display: flex;
        padding: 10px 16px 10px 16px;
        font-size: 14px;
        font-weight: bold;
        /* Use the Roboto font that is loaded in the <head> */
        font-family: 'Roboto', sans-serif;
     
    }
    
    .ToolbarButton:hover,
    .ToolbarButton:active{
        border: none;

        box-shadow: inset 0 0 20px #000000;
    }
    .ToolbarButton:nth-of-type(3){
        margin-right: 0px;
    }

    .TriangleRight {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid black;
        margin: auto;
        margin-top: 20px;
    }
    .TriangleDown {
        width: 0;
        height: 0;
        border-top: 5px solid black;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        margin: auto;
        margin-top: 20px;
    }
    .settingsButton{
        font-weight: 500;
        padding: 5px 10px;
        color: white;
        cursor: pointer;
        text-decoration: none;
        color: transparent;
        text-shadow: 0 0 0 black;
    }
    .settingsButton2{
        color: #757575;
        text-align: start;
        padding: 16px 10px 16px 25px;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
        
        border-left: solid 6px var(--color-2);
        margin-top: 2px;
        background-color: rgb(255, 255, 255);
        border-radius: none;
        font-weight: 100;
        font-style: normal;
    }
    .Playbutton{
        box-sizing: border-box;
        width: 100px;
        height: 100px;
        border-width: 50px 0px 50px 100px;
        border-color: transparent transparent transparent #202020;
        border-color: transparent transparent transparent var(--color-5);
       /*  background-color: rgba(204, 204, 204, 0); */
        background-color: rgba(204, 204, 204, 0);
        outline: none;
        margin: 50px 0 0 40px;
    
    }
    .Pausebutton{
        width: 80px;
        height: 80px;
        background-color: var(--color-5);
        border: none;
        outline: none;
        margin: auto;
        margin: 60px 0 0 0px;
    }
    .Playbutton_background{
        outline: none;
        width: 200px;
        height: 200px;
        background-color: var(--color-1);
        border: none;
        border-radius: 50%;
    }
    .Button{
        background-color: var(--color-2);
        border: none;
        color: rgb(255, 255, 255);
        outline: none;
        cursor: pointer;
        font: inherit;
        padding: 10px 20px;
        margin: 10px;
        font-weight: bold;
        height: 45;
        border-radius: 22.5px;
    }
    .dropdownButton{
        font-weight: 100;
        padding: 10px 10px;
        color: white;
        cursor: pointer;
        text-decoration: none;
        color: transparent;
        text-shadow: 0 0 0 black;
    }
    .NextButton{
        background-color: var(--color-2);
        color: rgb(255, 255, 255);
        border: none;
        outline: none;
        cursor: pointer;
        font: inherit;
        padding: 10px 10px;
        margin: 10px 3px 10px auto;
        font-weight: 500;
        font-size: 12px;
        height: 45;
        border-radius: 6px;
        width: 75px;
    }
    .PrevButton{
        background-color: var(--color-2);
        color: rgb(255, 255, 255);
        border: none;
        outline: none;
        cursor: pointer;
        font: inherit;
        padding: 10px 10px;
        margin: 10px auto 10px 0px;
        font-weight: 500;
        font-size: 12px;
        height: 45;
        border-radius: 6px;
        width: 75px;
    }
    .CancelButton{
        background-color: #ccc;
        /* background-color: rgb(48, 48, 48); */
        border: none;
        color: rgb(255, 255, 255);
        outline: none;
        cursor: pointer;
        font: inherit;
        padding: 10px 20px;
        margin: 10px;
        font-weight: bold;
        height: 45;
        border-radius: 22.5px;
        width: 100px;
    }
    
    .CancelButton:hover{
        opacity: 0.5;
    }
    .ContinueButton{
        background-color: rgb(53, 53, 53);
        /* background-color: rgb(48, 48, 48); */
        border: none;
        color: rgb(255, 255, 255);
        outline: none;
        cursor: pointer;
        font: inherit;
        padding: 10px 20px;
        margin: 10px;
        font-weight: bold;
        height: 45;
        border-radius: 22.5px;
        width: 100px;
    }
    .ContinueButton:hover{
        opacity: 0.5;
    }
    .settingsButton3{
        color: #757575;
        text-align: start;
        padding: 16px 10px 16px 25px;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
        
        border-left: solid 6px var(--color-2);
        margin-top: 2px;
        background-color: rgb(255, 255, 255);
        border-radius: none;
        font-weight: 100;
        font-style: normal;
    }
    
}
