@media(min-width: 1100px){

    
    .editForm{
        margin: auto;
        width: 600px;
        text-align: center;
        box-sizing: border-box;
        background-color: white;
        border-radius: 2px;
        position: fixed;
        z-index: 1000;
        top: 150px;
        left: calc(100vw * 0.5 - 300px);
        height: auto;
    }
    
    .editForm h2{
        text-align: center;
        padding: 20px 20px;
        margin: 0;
        font-size: 30px;
        font-weight: 500;
    }
    .editForm input{
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        width: 90%;
        margin: 5px auto;
        box-sizing: border-box;
        
    }
    .editForm textarea{
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        width: 90%;
        margin: 5px auto;
        box-sizing: border-box;
        height: 100px;
    }
    
    .editForm h3{
        text-align: start;
        margin: 0;
        padding: 5px 0 0 30px;
        font-weight: 100;
        font-size: 16px;
    }
}
@media(max-width: 1099px){

    
    .editForm{
        margin: auto;
        width: 90%;
        text-align: center;
        box-sizing: border-box;
        background-color: white;
        border-radius: 2px;
        position: fixed;
        z-index: 1000;
        top: 30px;
        left: 5%;
        height: auto;
    }
    

    .editForm input{
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        width: 90%;
        margin: 5px auto;
        box-sizing: border-box;
        
    }
    .editForm textarea{
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        width: 90%;
        margin: 5px auto;
        box-sizing: border-box;
        height: 50px;
    }
    
    .editForm h3{
        text-align: start;
        margin: 0;
        padding: 5px 0 0 15px;
        font-weight: 100;
        font-size: 16px;
    }
}
    