@media(min-width:1100px){

 .background{
  /*   background-image: url(../../Assets/Images/waves.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; */
    /* background-color: #fff6cc8c; */
    background-color: #fdf8e28c;
    
} 
.Player{
    display: grid;
    width: 430px;
    border-radius: 20px;
    background: inherit;
    position: absolute;
    height: auto;
    margin-left: 80px;
 /*    box-shadow: inset 0px 0px 12px -3px #c7c7c7; */
    color: transparent;
    text-shadow: 0 0 0 black;
    grid-gap: 10px;
}
.Player h1{
    padding: 10px 20px;
}

.Player .Play{
    margin: 50px auto;
}

.Audio{
    outline: none;
    height: 40px;
}


.Player .infobox{
    background-color: var(--color-1);
    height: auto;
    width: 100%;
    margin: auto;
    border-radius: 20px;
    min-height: 60px;
    z-index: 100;
    color: var(--color-5);
    
}
.Player .infobox .program{
    display: grid;
    grid-template-columns: 25% 75%;
}
.Player .infobox .moreInfo{
    display: grid;
    grid-template-columns: 25% 75%;
}

.Player .infobox .program .text{
    padding:  20px 10px 0 10px;
    font-size: 16px;
    text-align: start;
    grid-column: 2;
    margin-right: 10px;
}
  
.Player .infobox .moreInfo .text{
    padding:  20px 10px 0 10px;
    font-size: 18px;
    text-align: start;
    grid-column: 2;
}
.Player .infobox h2{
    text-align: start;
    font-style: inherit;
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    padding-top: 20px;
    padding-left: 30px;

}

.Player .infobox p:hover{
    padding: 15px 10px;
    cursor: pointer;
}
.Player .infobox p{
    margin: 0;
    padding:  0px 0 10px 0;
    font-size: 14px;
    font-style: normal;
    grid-column: span 2;
    text-align: center;
}
.Player .infobox .arrowDown {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 5px;
  }
.Player .infobox .arrowContainer:hover {
    cursor: pointer;
}

.Player .infobox .arrowUp {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}
@media(max-width:1100px){

.Player{
    /* background-color: #fff6cc8c; */
    background-color: #fdf8e28c;
    display: grid;
    width: 90%;
    border-radius: 20px;
    position: relative;
    height: auto;
    margin: 50px auto;
    grid-gap: 10px;
}
.Player h1{
    padding: 10px 20px;
}

.Player .Play{
    margin: 50px auto;
}

.Audio{
    outline: none;
    height: 40px;
}


.Player .infobox{
    background-color: var(--color-1);
    height: auto;
    width: 100%;
    margin: auto;
    border-radius: 20px;
    min-height: 60px;
    z-index: 100;
    color: var(--color-5);
}
.Player .infobox .program{
    display: grid;
    grid-template-columns: 25% 75%;
}
.Player .infobox .moreInfo{
    display: grid;
    grid-template-columns: 25% 75%;
}

.Player .infobox .program .text{
    padding:  20px 10px;
    font-size: 16px;
    text-align: start;
    grid-column: 2;
    margin-right: 10px;
}
.Player .infobox .moreInfo .text{
    padding:  20px 10px;
    font-size: 16px;
    text-align: start;
    grid-column: 2;
}
.Player .infobox h2{
    text-align: start;
    font-style: inherit;
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    padding: 20px 10px;

}

.Player .infobox p:hover{
    padding: 15px 10px;
    cursor: pointer;
}
.Player .infobox p{
    margin: 0;
    padding:  0px 0 10px 0;
    font-size: 14px;
    font-style: normal;
    grid-column: span 2;
    text-align: center;
}
.Player .infobox .arrowDown {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 5px;
  }
.Player .infobox .arrowContainer:hover {
    cursor: pointer;
}

.Player .infobox .arrowUp {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}