.NavigationItem{
    margin:0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: large;
}

.NavigationItem a {
    color: #757575;
    text-align: start;
    padding: 16px 20px 16px 25px;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    border-left: solid 6px var(--color-2);
    background-color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 100;
    margin-top: 2px;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    background-color: rgb(212, 209, 209);
}

@media (min-width: 1100px) {
    .NavigationItem{
        margin: 0;
        padding: 0;
        position: relative;
        display: block;
        box-sizing: border-box;
        height: 100%;
        width: auto;
        align-items: center;
    }

    
    .NavigationItem a {
        color: white;
        background-color: var(--color-1);
        height: 100%;
        min-width: 150px;
        width: auto;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
        text-align: center;
        /* font-family: var(--font-2); */
        font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 600;
        font-size: 18px;
        border-left: none;
        
    }
    
    .NavigationItem a:hover{
        background-color:var(--color-1);
       /*  border-bottom: 4px solid var(--color-2); */
        color: white;
        box-shadow: inset 0 0 10px #000000;
    }
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: var(--color-1);
        border-bottom: 5px solid var(--color-2);
        color: white;
        box-shadow: inset 0 0 10px #000000;
    }
     .NavigationItem:nth-of-type(1) {
       margin-left: 50px;
    }
   
   .NavigationItem:nth-of-type(4) {
        margin-right: auto;
    }  
}
