@media(min-width: 1100px){
    
    .number{
        background-color: var(--color-2);
        color: rgb(255, 255, 255);
        outline: none;
        cursor: pointer;
        font: inherit;
        padding: 7px 20px;
        margin: 10px;
        font-weight: bold;
        border-radius: 20px;
        height: auto;
        color: transparent;
        text-shadow: 0 0 0 black;
    }
    .number a{
        text-decoration: none;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 700;
    }
}
@media(max-width: 1099px){

    .number{
       display: none;
    }
}
