.SideDrawer {
    position: fixed;
    width: 100vw;
    height: auto;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding-top: 10px;
    box-sizing: border-box;

}


@media (min-width: 1100px) {
    .SideDrawer{
        display: none;
    }
}

.Open{
    transform: translateY(0);
}

.Close{
    transform: translateY(-100%);
}


