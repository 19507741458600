@media (min-width: 1100px){

.Mainpage_Wrapper{
    margin-top: 50px;
    height: auto;
}

.Mainpage_Wrapper h1{
    font-family: var(--font-1);
    font-size: 45px;
    font-weight: 100;
    margin: 0 0 10px 0;
    padding: 0;
}
.Mainpage_Wrapper .tittle_container{
    width: 100%;
    margin: auto;
    display: grid;
    position: relative;
    padding: 50px 0 0 0;
    grid-template-columns: 50% 50%;
    background-color: #ffe98f69;
    grid-template-rows: auto 100px;
    margin-bottom: 20px;
}


.Mainpage_Wrapper .tittle_container .logo_container{
    
    width: 100%;
}
.Mainpage_Wrapper .tittle_container .logo_container h1{
    font-size: 50px;
}
.Mainpage_Wrapper .tittle_container span{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: 100;
  }
.Mainpage_Wrapper .tittle_container img{
    width: 300px;
}
.Mainpage_Wrapper .tittle_container .triangle{
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background-color: var(--color-2);
    grid-column: 1 / span 2;
    grid-row: 2;
}
.Mainpage_Wrapper .tittle_container .triangle2{
    height: 100%;
    width: 100%;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    background-color: var(--color-4);
   
    opacity: 0.5;
    grid-column: 1 / span 2;
    grid-row: 2;
}

.Mainpage_Wrapper .sponsor{
    padding: 100px 0 50px 0;
    width: 60%;
    height: auto;
    margin: auto;
    position: relative;
    display: grid;
    grid-template-rows: 100px auto;

}
.Mainpage_Wrapper .sponsor h1{
    text-align: start;
    padding: 0 0 0 50px;
    font-size: 56px;
}

.Mainpage_Wrapper .sponsor .logos_container img{
    width: calc(100vw * 0.18) ;
}

.Mainpage_Wrapper .sponsor .logos_container{
    width: 100%;
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto auto auto;
    
    border-radius: 20px;
}
.Mainpage_Wrapper .sponsor .logos_container .logo_element{
    position: relative;
}
.Mainpage_Wrapper .sponsor .logos_container .delete_button{
    position: absolute;
    top: 0;
    opacity: 0.2;
    font-weight: 1000;
}
.Mainpage_Wrapper .sponsor .logos_container .delete_button:hover{
    position: absolute;
    top: 0;
    opacity: 1;
}

.Mainpage_Wrapper .sponsor .logos_container a{
   margin: 0;
   padding: 0;
   text-decoration: none;
   width: 200px;
   height: 100px;
}
.Mainpage_Wrapper .sponsor .logos_container img{
    opacity: 1;
}
.Mainpage_Wrapper .sponsor .logos_container img:hover{
    opacity: 1;
}

.Mainpage_Wrapper .Content_1{
    width: 60%;
    margin: auto;
    position: relative;
    display: grid;
    padding: 100px 0 50px 0;
    grid-template-rows: auto auto;
}
.Mainpage_Wrapper .Content_1 h1{
    text-align: start;
    padding: 0 0 0 50px;
    font-size: 56px;
}
.Mainpage_Wrapper .Content_1 .in_studio_container{
    height: 200px;
    background-color: rgba(255, 255, 255, 0.747);
    width: 100%;
    margin: 20px 0px;
    border-radius: 20px;
}
.Mainpage_Wrapper .Content_2{
    width: 60%;
    padding: 100px 0;
    margin: auto;
    position: relative;
    display: grid;
    grid-template-rows: auto auto;
  
}
.Mainpage_Wrapper .Content_2 h1{
    text-align: start;
    padding: 0 0 0 50px;
    font-size: 56px;
}
.Mainpage_Wrapper .Content_2 .shoutbox{
    height: 700px;
    background-color: rgba(255, 255, 255, 0.747);
    width: 100%;
    margin: 20px 0px;
    border-radius: 20px;
}
.Mainpage_Wrapper .section_separator{
    border-top: 1px solid black;
    width: 80%;
    margin: auto;
    box-shadow: 0px 0px 2px 0px black;
}
.Mainpage_Wrapper .calendar h1{
    text-align: start;
    padding: 0 0 0 50px;
    font-size: 56px;
}

.Mainpage_Wrapper .calendar{
    position: relative;
    max-width: 60%;
    margin: auto;
    min-width: 791px;
    padding: 100px 0;
}

.Mainpage_Wrapper .calendarMobile{
    display: none;
}
}

@media (max-width: 1100px){
    .Mainpage_Wrapper{
        margin-top: 50px;
        height: auto;
    }
    
    .Mainpage_Wrapper h1{
        font-family: var(--font-1);
        font-size: 40px;
        font-weight: 100;
        margin: 0 0 10px 0;
        padding: 0;
    }
    .Mainpage_Wrapper .tittle_container{
        width: 100%;
        display: grid;
        margin: auto;
        position: relative;
        padding: 50px 0 0 0;
        background-color: #ffda4769;
        grid-template-rows:  auto auto auto;
        margin-bottom: 20px;
        height: auto;
    }
    
    
    .Mainpage_Wrapper .tittle_container .logo_container{
        
        width: 100%;
    }
    .Mainpage_Wrapper .tittle_container .logo_container h1{
        font-size: 50px;
    }
    .Mainpage_Wrapper .tittle_container span{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 25px;
        font-style: italic;
        font-weight: 100;
        padding: 0 20px;
      }
    .Mainpage_Wrapper .tittle_container img{
        width: 300px;
    }
    .Mainpage_Wrapper .tittle_container .triangle{
        display: none;
    }
    .Mainpage_Wrapper .tittle_container .triangle2{
        display: none;
    }
    
    .Mainpage_Wrapper .sponsor{
        padding: 100px 0 50px 0;
        width: 90%;
        height: auto;
        margin: auto;
        position: relative;
        display: grid;
        grid-template-rows: auto auto auto auto;
    
    }
    .Mainpage_Wrapper .sponsor h1{
        text-align: start;
        padding: 0 0 0 10px;
        font-size: 40px;
    }
    
    .Mainpage_Wrapper .sponsor .logos_container img{
        width: 250px;
        margin: 10px 0;
    }
    
    .Mainpage_Wrapper .sponsor .logos_container{
        width: 100%;
    }
    .Mainpage_Wrapper .sponsor .logos_container .logo_element{
        position: relative;
    }
    .Mainpage_Wrapper .sponsor .logos_container .delete_button{
        position: absolute;
        top: 0;
        opacity: 0.2;
        font-weight: 1000;
    }
    .Mainpage_Wrapper .sponsor .logos_container .delete_button:hover{
        position: absolute;
        top: 0;
        opacity: 1;
    }
    
    .Mainpage_Wrapper .sponsor .logos_container a{
       margin: 0;
       padding: 0;
       text-decoration: none;
       width: 200px;
       height: 100px;
    }
    .Mainpage_Wrapper .sponsor .logos_container img{
        opacity: 1;
    }
    .Mainpage_Wrapper .sponsor .logos_container img:hover{
        opacity: 1;
    }
    
    .Mainpage_Wrapper .Content_1{
        width: 60%;
        margin: auto;
        position: relative;
        display: grid;
        padding: 100px 0 50px 0;
        grid-template-rows: auto auto;
    }
    .Mainpage_Wrapper .Content_1 h1{
        text-align: start;
        padding: 0 0 0 50px;
        font-size: 56px;
    }
    .Mainpage_Wrapper .Content_1 .in_studio_container{
        height: 200px;
        background-color: rgba(255, 255, 255, 0.747);
        width: 100%;
        margin: 20px 0px;
        border-radius: 20px;
    }
    .Mainpage_Wrapper .Content_2{
        width: 90%;
        padding: 50px 0;
        margin: auto;
        position: relative;
        display: grid;
        grid-template-rows: auto auto;
    
    }
    .Mainpage_Wrapper .Content_2 h1{
        text-align: start;
        padding: 0 0 0 10px;
        font-size: 40px;
    }
    .Mainpage_Wrapper .Content_2 .shoutbox{
        height: 700px;
        background-color: rgba(255, 255, 255, 0.747);
        width: 100%;
        margin: 20px 0px;
        border-radius: 20px;
    }
    .Mainpage_Wrapper .section_separator{
        border-top: 1px solid black;
        width: 80%;
        margin: auto;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .Mainpage_Wrapper .calendarMobile{
        position: relative;
        width: 90%;
        margin: auto;
        padding: 100px 0;
    }
    .Mainpage_Wrapper .calendarMobile h1{
        text-align: start;
        padding: 0 0 0 10px;
        font-size: 40px;
    }
    .Mainpage_Wrapper .calendar{
        display: none;
    }
}