  
  @keyframes animation {
    0% { 
      transform:  scale(0);
    }
    100% {
      transform:  scale(1);
    }
  }
  

  @media (min-width: 1100px) {

    .ContactData{
        margin: 20px auto;
        width: 600px;
        text-align: center;
        box-sizing: border-box;
        background-color: white;
        border-radius: 2px;
        position: fixed;
        z-index: 1000;
        top: 150px;
        left: calc(100vw * 0.5 - 300px);
        animation: animation 0.3s; 
    }
    
    .ContactData .Title h4{
        text-align: center;
        padding: 20px 20px;
        margin: 0;
        font-size: 30px;
        font-weight: 500;
    }
    
    .ContactData .ImageUpload{
        height: auto;
        padding: 20px 0px;
        display: grid;
    
    }
    .ContactData .ImageUpload h4{
        font-size: 16px;
        font-weight: 400;
        text-align: start;
        padding-left: 40px;
        margin: 10px 0;
    }
    .ContactData .ImageUpload .InputElement{
        color: black;
        padding: 10px 20px;
    }

}

@media (max-width: 1100px){
  
.ContactData{
    margin: 20px auto;
    width: 90%;
    text-align: center;
    box-sizing: border-box;
    background-color: white;
    border-radius: 2px;
    position: fixed;
    z-index: 1000;
    top: 150px;
    left: 5%;
    animation: animation 0.3s; 
    height: 330px;
}

.ContactData .ImageUpload{
    height: auto;
    padding: 20px 0px;
    display: grid;

}

.ContactData .Title h4{
    text-align: center;
    padding: 20px 20px;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}
.ContactData .ImageUpload h4{
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    padding-left: 10px;
    margin: 10px 0;
}

.ContactData .ImageUpload{
    height: 100px;
    padding: 20px 10px;

}
.ContactData .ImageUpload .InputElement{
    padding: 10px;
}

.warning {
    margin: 0;
    padding: 0 0 0 15px;
    color:rgb(255, 0, 0);
    text-align: start;
}
}

