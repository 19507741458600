.DrawerToggle {
    margin-left: auto;
    width: 65px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 5px;
    box-sizing: border-box;
    z-index: 100;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 1100px) {
    .DrawerToggle {
        display: none;
    }
}