@keyframes animation {
    0% { 
      transform:  scale(0);
    }
    100% {
      transform:  scale(1);
    }
  }
  
.programData{
    margin: 20px auto;
    width: 90%;
    left: 5%;
    text-align: center;
    box-sizing: border-box;
    animation: animation 0.3s; 
    position: absolute;
    z-index: 1000;
    background-color: white;
    
}

.programData .Title h4{
    text-align: center;
    padding: 20px 20px;
    margin: 0;
    font-size: 30px;
    font-weight: 500;
}

.programData .ImageUpload{
    height: 100px;
    padding: 20px 20px;

}
.programData .ImageUpload .InputElement{
    padding: 10px;
}

.warning {
    margin: 0;
    padding: 0 0 0 15px;
    color:rgb(255, 0, 0);
    text-align: start;
}


@media (min-width: 1100px) {

    .programData{
        margin: 20px auto;
        width: 600px;
        text-align: center;
        box-sizing: border-box;
        background-color: white;
        border-radius: 2px;
        position: fixed;
        z-index: 1000;
        top: 150px;
        left: calc(100vw * 0.5 - 300px);
        animation: animation 0.3s; 
    }
    
    .programData .Title h2{
        text-align: center;
        padding: 20px 20px;
        margin: 0;
        font-size: 30px;
        font-weight: 500;
    }
    
    .programData .ImageUpload h4{
        font-size: 16px;
        font-weight: 400;
        text-align: start;
        padding-left: 10px;
        margin: 10px 0;
    }
    .warning {
        margin: 0;
        padding: 0 0 0 40px;
        color:rgb(255, 0, 0);
        text-align: start;
    }
  

}