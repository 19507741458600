@media(min-width: 1100px){

    
    .Contact_Wrapper{
        margin-top: 50px;
        width: 60%;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.6);
    }
    .Contact_Wrapper h1{
        font-family: var(--font-1);
        font-size: 45px;
        text-align: start;
        
    }
    
    .Contact_Wrapper .contact_info{
        padding: 100px 0;
        width: 80%;
        margin: auto;
    }
    .Contact_Wrapper .contact_info .text_box{
        text-align: justify;

        line-height: 1.6;
    }
    .Contact_Wrapper .contact_info .Cursive{
        font-style: italic;
    }
    .Contact_Wrapper .contact_info .text_box2{
        margin-top: 50px;
    }
}
@media(max-width: 1099px){

    
    .Contact_Wrapper{
        margin-top: 50px;
        width: 90%;
        margin: auto;
    }
    .Contact_Wrapper h1{
        font-family: var(--font-1);
        font-size: 45px;
        text-align: start;
        
    }
    
    .Contact_Wrapper .contact_info{
        padding: 100px 0;
        width: 100%;
        margin: auto;
    }
    .Contact_Wrapper .contact_info .text_box{
        text-align: justify;

        line-height: 1.6;
    }
    .Contact_Wrapper .contact_info .Cursive{
        font-style: italic;
    }
    .Contact_Wrapper .contact_info .text_box2{
        margin-top: 50px;
    }
}