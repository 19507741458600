
    .footerWrapper {
        background-color: var(--color-1);
        width: 100vw;
    }
    .footerWrapper span{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        color: white;
        font-family: arial;
        font-size: 16px;
        font-weight: 200;
        display: block;
        padding-top: 20px;
        padding-bottom: 30px;
        width: 80%;
    }
    .footer {
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 34% 33% 33%;
        grid-template-rows: auto;
        width: 60%;
        overflow: hidden;
        padding: 30px 0;
    }

    .footer .footerBox_1{
        padding: 10px
    }
    .footer .footerBox_2{
        padding: 10px;
    }
    .footer .footerBox_2 a{
        color: white;
        padding: 5px;
    }

    .footer .footerBox_1 a{
        text-decoration: none;
        font-family: arial;
        font-size: 18px;
        color: white;
        display: block;
        font-weight: 200;
        width: 100%;
        padding-bottom: 5px;
    }

    .footer .footerBox_1 a:hover{
        color: var(--color-2);
    }

    .footer .footerBox_1 h2 {
        font-family: arial;
        font-size: 22px;
        color: white;
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }
    .footer .footerBox_2 h2 {
        font-family: arial;
        font-size: 22px;
        color: white;
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }


@media (max-width: 1099px){
    .footer {

        display: grid;
        grid-template-columns: auto;
        grid-template-rows:  auto auto auto;
        width: 60%;
        overflow: hidden;
        padding: 70px 0;
    }
}
