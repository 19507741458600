.Player {
    width: 100vw;
    height: 60px;
    background-color: var(--color-2);
    position: fixed;
    bottom: 0;    
    animation: popup 0.5s ease-out;
    z-index: 100;
    color: transparent;
    text-shadow: 0 0 0 black;
}

.Audio{
    height: 40px;
    outline: none;
    margin-top: 10px;
}
@keyframes popup{
    from {
        transform: translateY(100px);
    }
    to {
        transform: translateY(0);
    }
}