@media (min-width: 1100px){
.dummyChat{
    height: 700px;
    background-color: rgba(0, 0, 0, 0.7);
    height: auto;
    border-radius: 20px;
    color: transparent;
    text-shadow: 0 0 0 black;
    display: grid;
    grid-template-rows: auto auto auto;
 
}

.dummyChat .dummyChatTop{
    height: 90px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px 20px 0 0;
}
.dummyChat .dummyChatBottom{
    height: 150px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0 0 20px 20px;
}

.dummyChat .dummyMessage{
    overflow-y: scroll;
    height: 550px;
    padding: 0px 0;
    margin-top: 0px;
   /*  display: flex;
    align-items: center;
    justify-content: center; */
}



.dummyChat h2{
    font-size: 30px;
    margin: 0;
    padding: 100px 0;
    color: white;
    color: transparent;
    text-shadow: 0 0 0 rgb(255, 255, 255);
 
}

.infoText{
    color: white;
    margin: 20px 20px;
    grid-column-start: 2;
}
.signInButton{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rules{
    color: white;
    margin: 20px 20px;
}
.rules li{
    text-align: start;
}

.rules h3{
    text-align: start;
    padding: 0 0 0 50px;
}

.rules .showRules:hover{
    cursor: pointer;
}

.SignIn{
    height: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-rows: auto auto;
    border-radius: 20px;
    
}


.shoutbox{
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-rows: auto auto;
    border-radius: 20px;
}
.shoutbox .signOut Button{
    margin-top: 20px;
    margin-bottom: 30px;
}

.shoutbox .signOut{
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.shoutbox .messages_section{
    overflow-y: scroll;
    height: 550px;
    padding: 0px 0;
    margin-top: 0px;
    
}
.shoutbox .form_section_background{
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0 0 20px 20px;
}
.shoutbox .form_section{
    height: auto;
    width: 90%;
    margin: 0 20px 0 30px;
    display: grid;
    grid-template-columns: 85% 15%;
    padding: 20px 20px 20px 0;
}
.shoutbox .form_section .username_input{
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
    grid-row: 1;
    grid-column: 1;
    border-radius: 3px;
    
}
.shoutbox .form_section .text_input{
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
    grid-row: 2;
    grid-column: 1;
    border-radius: 3px;
}
.shoutbox .form_section .shout_button{
    outline: none;
    height: auto;
    background-color: var(--color-2);
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    margin: 10px auto;
    box-sizing: border-box;
    grid-column: 2;
    grid-row: 1/span 2;
    font-size: 30px;
    font-weight: 1000;
    color: white;
    border-radius: 10px;
    border: none;
}
.shoutbox .form_section .shout_button:hover{
   /*  box-shadow: inset 0px 0px 5px #000000; */
    cursor: pointer;
    background-color: var(--color-4);
}

.shoutbox .form_section .warningMessage{
    color: red;
}

.shoutbox .form_section .charactersLeft{
    padding: 0 0 0 5%;
    margin: 0;
    text-align: start;
    color: white;
    font-size: 12px;
    line-height: 1;
}

.message{
    background-color: rgba(0, 0, 0, 0.151);
    padding: 5px 50px;
    width: auto;
    margin: auto;
    min-height: 30px;
    height: auto;
/*     display: flex;
    flex-flow: row; */
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    margin-top: 2px;
}

.showMoreMessages{
    background-color: rgba(0, 0, 0, 0.151);
    padding: 10px 50px;
    width: auto;
    margin: auto;
    min-height: 30px;
    height: auto;
    display: flex;
    text-align: center;
 
    color: white;
    color: transparent;
    text-shadow: 0 0 0 white;
}

.showMoreMessages .tag{
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    line-height: 2;
    border-radius: 10px;
}
.showMoreMessages .tag:hover{
    cursor: pointer;
}


.message .username{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: start;
    width: auto;
    align-content: flex-start;
}
.message .usernameAdmin{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: start;
    width: auto;
    align-content: flex-start;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1;
}

.message .usernameMaster{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: start;
    width: auto;
    align-content: flex-start;
    color: rgb(255, 255, 255);
}

.message .text{
    color: rgb(255, 255, 255);
    font-weight: 400;
    text-align: start;
    font-family: 'Noto Sans JP', sans-serif;
    min-height: 30px;
    height: auto;
    padding: 5px 20px 0 0px;
    width: auto;
    max-width: 90%;
}
.message .time{
    color: rgb(255, 255, 255);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: 13px;
    text-align: end;
    margin-left: auto;
}
.message .id{
    color: rgb(207, 207, 207);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: 13px;
    text-align: end;
    margin-left: auto;
    margin-right: 0px;
}

}

@media (max-width: 1099px){
.dummyChat{
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
    height: auto;
    border-radius: 20px;
    display: grid;
    grid-template-rows: auto auto auto;
}

.dummyChat h2{
    font-size: 30px;
    margin: 0;
    padding: 100px 0;
    color: white;
}
.dummyChat .dummyChatTop{
    height: 80px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px 20px 0 0;
}
.dummyChat .dummyChatBottom{
    height: 190px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0 0 20px 20px;
}

.dummyChat .dummyMessage{
    overflow-y: scroll;
    height: 450px;
    padding: 0px 0;
    margin-top: 0px;
    display: flex;
    justify-content: center;
}


.infoText{
    color: white;
    margin: 20px 20px;
}
.signInButton{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rules{
    color: white;
    margin: 20px 20px;
}

.rules li{
    text-align: start;
}

.rules h3{
    text-align: start;
    padding: 0 0 0 10px;
}

.rules .showRules:hover{
    cursor: pointer;
}

.SignIn{
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-rows: auto auto;
    border-radius: 20px;
    
}
/* .SignIn Button{
    margin-right: 100px;
 } */

.shoutbox{
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-rows: auto auto;
    border-radius: 20px;
    align-items: center;
}

.shoutbox .signOut{
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shoutbox .signOut Button{
    margin-top: 20px;
    margin-bottom: 20px;
}
.shoutbox .messages_section{
    overflow-y: scroll;
    height: 450px;
    padding: 0px 0;
    margin-top: 0px;
    
}
.shoutbox .form_section_background{
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0 0 20px 20px;
}
.shoutbox .form_section{
    height: auto;
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-rows: auto auto;
    padding: 5px;
}
.shoutbox .form_section .username_input{
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
    grid-row: 1;
    grid-column: 1;
    border-radius: 3px;
    
}
.shoutbox .form_section .text_input{
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
    grid-row: 2;
    grid-column: 1;
    border-radius: 3px;
}
.shoutbox .form_section .shout_button{
    outline: none;
    height: auto;
    background-color: var(--color-2);
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
    
    font-size: 30px;
    font-weight: 1000;
    color: white;
    border-radius: 10px;
    border: none;
}
.shoutbox .form_section .shout_button:hover{
   /*  box-shadow: inset 0px 0px 5px #000000; */
    cursor: pointer;
    background-color: var(--color-4);
}
.shoutbox .form_section .warningMessage{
    color: red;
}

.shoutbox .form_section .charactersLeft{
    padding: 0 0 0 5%;
    margin: 0;
    text-align: start;
    color: white;
    font-size: 12px;
    line-height: 1;
}

.message{
    background-color: rgba(0, 0, 0, 0.151);
    padding: 10px 10px;
    width: auto;
    margin: auto;
    min-height: 30px;
    height: auto;
/*     display: flex;
    flex-flow: row; */
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto  auto auto;
    margin-top: 2px;
}

.showMoreMessages{
    background-color: rgba(0, 0, 0, 0.151);
    padding: 10px 20px;
    width: auto;
    margin: auto;
    min-height: 30px;
    height: auto;
    display: flex;
    text-align: center;
    margin-top: 2px;
    color: white;
    color: transparent;
    text-shadow: 0 0 0 white;
}

.showMoreMessages .tag{
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    line-height: 2;
    border-radius: 10px;
}

.message .username{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 14px;
    text-align: start;
    width: auto;
    align-content: flex-start;
}
.message .usernameAdmin{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: start;
    width: auto;
    align-content: flex-start;
    font-style: normal;
    color: rgb(255, 255, 255);
}

.message .usernameMaster{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: start;
    width: auto;
    align-content: flex-start;
    color: rgb(255, 255, 255);
}

.message .text{
    color: rgb(255, 255, 255);
    font-weight: 400;
    text-align: start;
    font-family: 'Noto Sans JP', sans-serif;
    width: auto;
    height: auto;
    padding: 6px 10px 6px 0px;
    font-size: 14px;
    max-width: 100%;
}
.message .time{
    color: rgb(255, 255, 255);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: 13px;
    text-align: end;
    margin-left: auto;
}
.message .id{
    color: rgb(207, 207, 207);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: 13px;
    text-align: end;
    margin-left: auto;
    margin-right: 20px;
}
}