.Auth{
    position: absolute;
    width: 90%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--color-6);
    top: 100px;
    left: 5%;
}

@media (min-width: 1100px) {
    .Auth {
        width: 500px;
        left: calc(100vw * 0.5 - 250px);
        top: 100px;
    }
    .Auth .Input{
        margin: 10px;
    }
}