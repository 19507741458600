:root{
  --color-1: #2a2c2e;
  --color-2: #ffb414;
  --color-3: #FFDA47;
  --color-4: #FFE270;
  --color-5: #FFFBEB;
  --color-6: rgba(255, 255, 255, 0.747);
  --font-1: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  --font-2: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.App {
  text-align: center;
  color: transparent;
  text-shadow: 0 0 0 black;
  background-color: var(--color-5);
  background-image: url(./Assets/Images/Background3.png);
  background-color: #f8edca;
  background-blend-mode: lighten;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x:hidden;
}

.App .page_wrapper{
  margin-left: 20%;
  width: 100vw;
  min-height: 100vh;
  margin: auto;
}



@media (max-width: 1100px){
  .App {
    text-align: center;
    color: transparent;
    text-shadow: 0 0 0 black;
    background-color: var(--color-5);
    background-image: url(./Assets/Images/Mobile.jpg);
    background-color: #f8edca;
    background-blend-mode: lighten;
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x:hidden;
  }
  
  .App .page_wrapper{

    width: 100vw;
    min-height: 100vh;
    margin: auto;
  }
  
}  


 
